/* Footer.css */

#footer {
	background-color: var(--background-color);
	border-top: 2px solid var(--action);
	font-family: var(--sansSerif);
	margin: 1em 0 0 0;
}

#footerLinks {
	display: flex;
	justify-content: space-evenly;
	padding: 1em 0 0 0;
}

#footerCopyright {
	display: flex;
	justify-content: space-around;
	font-size: .8em;
	margin: 1em 0 .5em 0;
}