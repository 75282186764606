/* DishesSearchResults.css */

/* @media screen and (max-width: 600px) {
  .dishesSearchResults {
    max-width: 100%;
  }
}
@media screen and (min-width: 601px) {
  .dishesSearchResults {
    max-width: 500px;
  }
} */

#dishesSearchResultsContainer {
  position: absolute;
  height: max-content;
  z-index: 1;
}

#dishesSearchResults {
  /* width set dynamically in component */
  background-color: var(--background-color);
  border-left: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
  border-right: 1px solid var(--gray);
  font-size: 1.25em;
}

.dishSearchResult {
  padding-left: 0.25em;
  border: 2px solid var(--background-color);
  color: #4c6464;
}

.available {
  color: var(--action);
  cursor: pointer;
}

.unavailable {
  color: #4c6464;
}

.available:active {
  border: 2px solid var(--action);
}
.dishHighlightedSuggestion {
  border: 2px solid var(--action);
}

.firstUnavailable {
  border-top: 1px solid var(--gray);
}

#filteredOutMsg {
  font-size: 0.8em;
  margin: .4em 0 .2em 0;
  /* text-align: center; */
}
