/* NotFound.css */

#notFoundMsg {
  border: 1px solid var(--action);
  border-radius: 5px;
  width: 400px;
  height: 100px;
  margin: auto;
  padding: 2em;
  font-size: 1.5em;
  font-family: var(--sansSerif);
}