/* HomeSearchResults.css */

#homeSearchResultsContainer {
  position: absolute;
  height: max-content;
  margin: -1em 0 0 0;
  z-index: 1;
}

#homeSearchResults {
  /* width set dynamically in component */
  border-left: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
  border-right: 1px solid var(--gray);
}

#homeSearchResultsHeader {
  /* border: 1px solid red; */
  display: flex;
  /* justify-content: flex-start; */
}

#searchHeaderIngredients {
  /* border: 1px solid red; */
  width: 100%;
}

.searchCategory {
  /* text-decoration: underline; */
  font-weight: 600;
  /* font-size: 1.2em; */
  font-family: var(--sansSerif);
  color: var(--action);
  /* width: 100%; */
  padding: 0.5em;
  background-color: var(--background-color);
  cursor: pointer;
  /* margin: 0 .5em 0 .5em; */
}

.searchCategory.inactiveLeft {
  border-right: 1px solid var(--black);
  border-bottom: 1px solid var(--black);
  border-radius: 0 0 3px 0;
  background-color: var(--white);
}

.searchCategory.inactiveRight {
  border-left: 1px solid var(--black);
  border-bottom: 1px solid var(--black);
  border-radius: 0 0 0 3px;
  background-color: var(--white);
}

#searchResultsLists {
  background-color: var(--background-color);
  font-size: 1.25em;
}

.linkOverride {
  color: var(--action);
  width: 100%;
}

.homeSearchResult {
  color: var(--action);
  cursor: pointer;
  padding-left: 0.25em;
  border: 2px solid var(--background-color);
}

.homeSearchResult:active {
  border: 2px solid var(--action);
}

.notFoundMsg {
  padding-left: 0.25em;
  border: 2px solid var(--background-color);
}

.homeHighlightedSuggestion {
  border: 2px solid #299899;
}
