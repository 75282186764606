/* Dish.css */

#dishPage {
  display: flex;
  /* gap: 2em; */
  width: 100%;
  /* padding: 0 1em 0 1em; */
}

#dishPage > div {
  /* border: 1px solid rebeccapurple; */
  margin: 0 1em 0 1em;
}

@media (max-width: 768px) {
  #dishPage {
    flex-direction: column;
    align-items: center;
  }
  #dishData {
    width: 95%;
  }
  #recipeSection {
    width: 95%;
  }

  .flexy {
    display: none;
  }
}

@media (min-width: 769px) {
  #dishPage {
    flex-direction: row;
    justify-content: center;
    /* margin: 0 1em 0 1em; */
  }

  .sticky {
    display: none;
  }
  #dishData {
    width: 350px;
  }

  #recipeSection {
    width: 419px;
  }
}

/* #debugWindow {
  border: 1px solid lightcoral;
  margin: 0.5em;
  padding: 0.5em;
} */

#dishTitleArea {
  /* border: solid 1px red; */
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-family: var(--sansSerif);
}

#dishNameAndCount {
  display: flex;
  flex-direction: column;
}

#dishNameAndCount > h1 {
  line-height: 95%;
  margin-right: 1em;
}

#numRecipes {
  margin: 0.5em 0 0.5em 0;
}

#dishSearchContainer {
  margin: 0.5em 0.5 0 0.5em;
}

#recipeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#findARecipeTitle {
  padding: 1% 1% 1% 1%;
  border-top: 1px solid var(--action);
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  width: 98%;
  background: linear-gradient(
    to top,
    rgba(232, 244, 244, 1),
    rgba(232, 244, 244, 0.85)
  );
  text-align: center;
}

.sticky{
  cursor: pointer;
}

#secondFilterStatusBox {
  /* border: 1px solid red; */
  width: 95%;
}
