/* ErrorBoundary.css */

.errorBoundary {
  width: 80%;
  margin: auto;
  margin-top: 60px;
  padding: 1em;
  border: 2px solid #209899;
}
/* Contact.css */

#contactWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contactContent {
  /* border: 1px solid var(--action); */
  border-radius: 5px;
  max-width: 600px;
  width: 95%;
  font-family: var(--sansSerif);
  font-size: 1.2em;
}

#contactHeader {
 margin: 0 0 1em 0;
}

#contactForm {
  /* margin: 10px; */
}

#contactEmailInput {
  width: 99%;
  margin-bottom: 1em;
  font-size: 1em;
}

#contactMessageTextarea {
  width: 98%;
  height: 300px;
  font-size: 1em;
  padding: 5px;
  font-family: var(--sansSerif);
}

#contactSubmit {
  color: var(--action);
  height: 40px;
  background-color: var(--background-color);
  font-size: 1em;
  border: 1px solid var(--black);
  border-radius: 5px;
}

#contactSubmit:active {
  color: var(--white);
  background-color: var(--action);
}

#submitConfirmSuccess {
  /* border: 1px solid lightgreen; */
  padding: 0.5em;
  margin-bottom: 10px;
}

#submitConfirmError {
  border: 1px solid lightcoral;
  padding: 0.5em;
  margin-bottom: 10px;
}
/* ComponentTabs.css */

.componentTabs {
  display: flex;
  margin-top: 1em;
}

.componentTab {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0.5em;
  margin-right: 0.5em;
  cursor: pointer;
}

.highlightedComponent {
  background-color: #81b8b8;
}
/* DishesIngSearchBar.css */

/* @media screen and (max-width: 600px) {
  .dishSearchBar {
    max-width: 100%;
  }
}
@media screen and (min-width: 601px) {
  .dishSearchBar {
    max-width: 500px;
  }
} */

#dishSearchBarInput {
  box-sizing: border-box;
  /* margin-left: 0.5em; */
  /* margin-right: 0.5em; */
  height: 35px;
  width: 100%;
  border: 1px solid var(--gray);
  font-size: 1.2em;
  padding-left: 6px;
  /* background-color: var(--white); */
}

/* DishesSearchResults.css */

/* @media screen and (max-width: 600px) {
  .dishesSearchResults {
    max-width: 100%;
  }
}
@media screen and (min-width: 601px) {
  .dishesSearchResults {
    max-width: 500px;
  }
} */

#dishesSearchResultsContainer {
  position: absolute;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  z-index: 1;
}

#dishesSearchResults {
  /* width set dynamically in component */
  background-color: var(--background-color);
  border-left: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
  border-right: 1px solid var(--gray);
  font-size: 1.25em;
}

.dishSearchResult {
  padding-left: 0.25em;
  border: 2px solid var(--background-color);
  color: #4c6464;
}

.available {
  color: var(--action);
  cursor: pointer;
}

.unavailable {
  color: #4c6464;
}

.available:active {
  border: 2px solid var(--action);
}
.dishHighlightedSuggestion {
  border: 2px solid var(--action);
}

.firstUnavailable {
  border-top: 1px solid var(--gray);
}

#filteredOutMsg {
  font-size: 0.8em;
  margin: .4em 0 .2em 0;
  /* text-align: center; */
}

/* FilterStatusBox.css */

/* @media screen and (max-width: 600px) {
  .filterStatusBox {
    max-width: 100%;
  }
}
@media screen and (min-width: 601px) {
  .filterStatusBox {
    max-width: 500px;
  }
} */

.list {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25em;
}

.listItem {
  box-sizing: border-box;
  padding-left: 0.25em;
  margin: 0.5em 0.5em 0 0;
  display: flex;
  border-radius: 5px;
  align-items: center;
}

.includeItem {
  border: 1px solid var(--action);
  background-color: var(--background-color);
  display: flex;
}

.excludeItem {
  border: 1px solid var(--excluded);
  background-color: var(--excluded-background-color);
  display: flex;
}

.removeButton {
  cursor: pointer;
}

#ingFiltersClearAll {
  color: var(--action);
  cursor: pointer;
  border: 1.5px solid var(--action);
  padding: 0 5px 4px 5px;
  font-family: var(--sansSerif);
}
/* RecipeLinks.css */

#recipeLinks {
  margin: 1em 0 0 0;
  /* border: 1px solid red; */
  min-width: 330px;
  /* width: 100%; */
}

.recipeLink {
  border-left: 2px solid var(--mediumGray);
  padding-left: 5px;
  margin: 0 0 1em 0;
}

.sourceLink a {
  color: var(--action);
  cursor: pointer;
  text-decoration: none;
  font-size: 1.2em;
}

.sourceSite {
  display: flex;
  /* border: 1px solid red; */
  grid-gap: 5px;
  gap: 5px;
}


/* RecipeSources.css */

#recipeSourcesContainer {
  /* border: 1px solid pink; */
  margin-bottom: 0.5em;
}

.source {
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  /* border-bottom: 1px solid lightgray; */
  margin: 0.4em 0 0 1em;
  padding-right: .5em;
}

.source > input {
  width: 2em;
  height: 2em;
  margin-right: 0.5em;
  
}

.sourceLockedOut {
  color: gray;
}

.onlyButton {
  color: var(--action);
  margin: 0 .2em .2em .2em;
  /* font-size: .8em; */
  cursor: pointer;
  font-family: var(--sansSerif);
}

/* FindARecipe.css */

#recipes {
  /* border: 2px solid purple; */
  /* width: 95%; */
  /* max-width: 600px; */
  width: 100%;
}


#recipesHeader {
  display: flex;
  flex-direction: column;
  font-family: var(--sansSerif);
  font-size: 1.25em;
  margin: 0.5em 0 0 0;
  /* padding: .25em; */
  border: 2px solid var(--action);
  border-radius: 5px;
}

/* @media (max-width: 768px) {
  #secondFilterStatusBox {
    
  }
} */

@media (min-width: 769px) {
   #secondFilterStatusBox {
    display: none;
  }
}

#filterBySourceHeader {
  display: flex;
  justify-content: space-between;
  
}

#filterBySourceButton {
  padding: 0.25em;
  color: var(--action);
  cursor: pointer;
  display: flex;
  flex-grow: 2;
  align-items: center;
  /* border: 1px solid green; */

}

#sourcesResetButton {
  /* margin: 0 0 0 1em; */
  color: var(--action);
  cursor: pointer;
  padding: 0.25em 0.5em 0 0;
}


/*IngredientBranch.css*/



.ingredientLine {
  /* display: grid; */
  /* grid-template-columns: 20px auto; */
  display: flex;
  /* border: 1px solid red; */
}

.gen_0 {
  font-size: 1.2em;
}

.toggleButton {
  width: 24px;
  display: table;
  margin-left: -5px;
  cursor: pointer;
  /* border: 1px solid gray; */
}

.toggleButton > span {
  display: table-cell;
  vertical-align: middle;
}

.ingredientLineFlexWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  /* border: 1px solid orange; */
}

.ingredientLine > * {
  align-self: center;
}

.nameAndPercent {
  /* for the highlighting */
  margin-left: -4px;
  padding-left: 4px;
  margin-right: -3px;
  padding-right: 3px;
  border-radius: 5px;
  /* ------------------- */
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid yellow; */
}

.clickableName {
  cursor: pointer;
}

.highlight {
  background-color: rgba(235, 225, 52, 0.4);
}

.nameContainer {
  flex-grow: 2;
  min-height: 24px;
  position: relative;
  display: table;
  /* border: 1px solid greenyellow; */
  margin: 0 0.4em 0 0;
}

.name {
  display: table-cell;
  vertical-align: middle;
  line-height: 1.1em;
  padding: 4px 0 3px 0;
  /* display: table; */
  /* width: 100%; */
}

#ingredientInfoIcon {
  margin-right: 0.25em;
  vertical-align: middle;
  padding-top: 0.1em;
}

.percentage {
  font-size: 0.9em;
  margin: auto;
}


.filterButtons {
  display: grid;
  grid-template-columns: 25px 25px;
  color: var(--action);
  font-weight: bold;
  font-size: 1.5em;
  cursor: pointer;
  margin-left: 5px;
  margin-right: -4px;
  text-align: right;
}

.filterButton {
  display: table;
}

.filterButton > span {
  display: table-cell;
  vertical-align: middle;
}

/* IngredientTree.css */

/* @media screen and (max-width: 600px) {
  .ingredientTree {
    max-width: 100%;
  }
}
@media screen and (min-width: 601px) {
  .ingredientTree {
    max-width: 500px;
  }
} */

#ingredientTree {
  position: relative;
  margin: .5em 0 0 0;
}

#ingInfoBox {
  background-color: var(--white);
  border: 1px solid var(--action);
  border-radius: 5px;
  padding: 0.5em;
  text-align: center;
  width: 100px;
  position: absolute;
  right: 5.5em;
  top: 0em;
  margin-top: 0.5em;
  font-family: var(--sansSerif);
  cursor: pointer;
}

/* HighlightsAndFiltersButtons.css */

#HaFbutton {
  border: 2px solid var(--action);
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
  color: var(--action);
  font-size: 1em;
  padding: 3px;
  cursor: pointer;
}
#HaFbutton > span {
  font-size: 1.25em;
  margin: 0.125em;
}


/* Dish.css */

#dishPage {
  display: flex;
  /* gap: 2em; */
  width: 100%;
  /* padding: 0 1em 0 1em; */
}

#dishPage > div {
  /* border: 1px solid rebeccapurple; */
  margin: 0 1em 0 1em;
}

@media (max-width: 768px) {
  #dishPage {
    flex-direction: column;
    align-items: center;
  }
  #dishData {
    width: 95%;
  }
  #recipeSection {
    width: 95%;
  }

  .flexy {
    display: none;
  }
}

@media (min-width: 769px) {
  #dishPage {
    flex-direction: row;
    justify-content: center;
    /* margin: 0 1em 0 1em; */
  }

  .sticky {
    display: none;
  }
  #dishData {
    width: 350px;
  }

  #recipeSection {
    width: 419px;
  }
}

/* #debugWindow {
  border: 1px solid lightcoral;
  margin: 0.5em;
  padding: 0.5em;
} */

#dishTitleArea {
  /* border: solid 1px red; */
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-family: var(--sansSerif);
}

#dishNameAndCount {
  display: flex;
  flex-direction: column;
}

#dishNameAndCount > h1 {
  line-height: 95%;
  margin-right: 1em;
}

#numRecipes {
  margin: 0.5em 0 0.5em 0;
}

#dishSearchContainer {
  margin: 0.5em 0.5 0 0.5em;
}

#recipeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#findARecipeTitle {
  padding: 1% 1% 1% 1%;
  border-top: 1px solid var(--action);
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  width: 98%;
  background: linear-gradient(
    to top,
    rgba(232, 244, 244, 1),
    rgba(232, 244, 244, 0.85)
  );
  text-align: center;
}

.sticky{
  cursor: pointer;
}

#secondFilterStatusBox {
  /* border: 1px solid red; */
  width: 95%;
}

/* Header.css */


#header {
  padding: .75em .75em .5em .75em;
	margin: 0 0 1em 0;
  display: flex;
  justify-content: space-between;
	align-items: center;
	/* background: linear-gradient(to left, var(--teal), transparent); */
	background-color: var(--background-color);
	border-bottom: 2px solid var(--action);
	font-family: var(--sansSerif);
	font-size: 1.1em;
	color: var(--action);
}

#headerLogoSVG {
  width: 40px;
}

#menuLinks {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
}

#burgerWrapper {
  position: absolute;
  top: 16px;
  right: 16px;
  font-family: var(--sansSerif);
	font-size: 1.1em;
}

#burgerIcon {
  cursor: pointer;
}

#burgerIcon > span {
  font-size: 2em;
}

#navDropdown {
  width: 130px;
  position: absolute;
  top: 35px;
  background-color: var(--white);
  border: 2px solid var(--action);
  border-radius: 5px;
	padding: .5em;
  z-index: 1;
  right: 10px;
  display: flex;
  flex-direction: column;
  text-align: end;
}

.dropdownLink {
  font-size: 1.1em;
  margin-bottom: 0.5em;
  cursor: pointer;
}

@media (max-width: 1024px) {
  #menuLinks {
    display: none;
  }
}

/* @media (min-width: 769px) {
  #burgerWrapper {
    display: none;
  }
} */

@media (min-width: 1025px) {
  #burgerWrapper {
    display: none;
  }
}

/* ExistingSearchTerms.css */
#existingSearchTerms {
  margin: 0 0 0.5em 0;
  max-width: 600px;
  width: 95%;
}

#existingSearchTermsHeader {
  align-items: baseline;
  max-width: 800px;
}

#existingSearchTermsWrapper {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
}

.existingSearchTerm {
  border: 1px solid var(--action);
  box-sizing: border-box;
  padding-left: 0.5em;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  display: flex;
  border-radius: 5px;
  align-items: center;
}

#existingSearchTermClearAll {
  padding-right: 0.5em;
  color: var(--action);
  cursor: pointer;
  padding-left: 0.5em;
  border: 1.5px solid var(--action);
  font-family: var(--sansSerif);
  padding: 0 5px 5px 5px;
  font-size: 1.1em;
}

/* HomeSearchResults.css */

#homeSearchResultsContainer {
  position: absolute;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin: -1em 0 0 0;
  z-index: 1;
}

#homeSearchResults {
  /* width set dynamically in component */
  border-left: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
  border-right: 1px solid var(--gray);
}

#homeSearchResultsHeader {
  /* border: 1px solid red; */
  display: flex;
  /* justify-content: flex-start; */
}

#searchHeaderIngredients {
  /* border: 1px solid red; */
  width: 100%;
}

.searchCategory {
  /* text-decoration: underline; */
  font-weight: 600;
  /* font-size: 1.2em; */
  font-family: var(--sansSerif);
  color: var(--action);
  /* width: 100%; */
  padding: 0.5em;
  background-color: var(--background-color);
  cursor: pointer;
  /* margin: 0 .5em 0 .5em; */
}

.searchCategory.inactiveLeft {
  border-right: 1px solid var(--black);
  border-bottom: 1px solid var(--black);
  border-radius: 0 0 3px 0;
  background-color: var(--white);
}

.searchCategory.inactiveRight {
  border-left: 1px solid var(--black);
  border-bottom: 1px solid var(--black);
  border-radius: 0 0 0 3px;
  background-color: var(--white);
}

#searchResultsLists {
  background-color: var(--background-color);
  font-size: 1.25em;
}

.linkOverride {
  color: var(--action);
  width: 100%;
}

.homeSearchResult {
  color: var(--action);
  cursor: pointer;
  padding-left: 0.25em;
  border: 2px solid var(--background-color);
}

.homeSearchResult:active {
  border: 2px solid var(--action);
}

.notFoundMsg {
  padding-left: 0.25em;
  border: 2px solid var(--background-color);
}

.homeHighlightedSuggestion {
  border: 2px solid #299899;
}

/* Home.js */
/* HomeSearchBar.js */
/* CommonlyPaired.js */
/* DishesThatInclude.js */

#homePage {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

#homeSplash {
  /* border: 1px solid red; */
  margin: 7em 0.5em 0.5em 0.5em;
  font-family: var(--sansSerif);
  max-width: 600px;
  width: 95%;
}

#warning {
  margin: 1em 0 0 0;
  color: var(--coral);
  display: flex;
  align-items: center;
}

#warning > span {
  color: var(--coral);
  margin: 0 0.25em 0 0;
}

/* ------------------------------------------- */
/* HomeSearchBar.js */

#homeSearchContainer {
  max-width: 600px;
  width: 95%;
}

/* #homeSearchBar {
  display: flex; 
  justify-content: space-around;
   max-width: 800px;
} */

#homeSearchBarInput {
  box-sizing: border-box;
  margin: 0 0 1em 0;
  height: 35px;
  width: 100%;
  border: 1px solid var(--gray);
  font-size: 1em;
  padding-left: 6px;
  /* background-color: var(--white); */
}

input:focus,
textarea:focus {
  outline: 0;
}

/* ---------------------------------------------- */

/* CommonlyPaired.js */

/* Gene's stuff 28 DEC 2021 */
#commonly {
  /* align-self: flex-start; */
  background-color: var(--background-color);
  padding: 0.5em 0 0.75em 0;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 600px;
}

#commonly > h3 {
  margin: 0 0 0 0.25em;
}

#optionsSlider {
  /* width: 100%;
  max-width: 600px; */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#optionsSlider > div {
  display: flex;
}

.commonlyIngredient {
  background-color: var(--backgroundWhite);
  color: var(--action);
  border: 1px solid var(--action);
  border-radius: 5px;
  /* padding: 0.2em 0.4em 0.2em 0.4em; */
  padding: 4px 6px 4px 6px;
  margin: 0.25em;
  cursor: pointer;
  /* font-weight: bold; */
}

.lockoutCP {
  /* border: 1px solid red; */
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
}

/* ------------------------------------------------- */

/* DishesThatInclude.js */

.dishOptions {
  max-width: 600px;
  width: 95%;
  margin: 0.5em 0 0 0;
  /* max-width: 800px; */
  position: relative;
}

#dishOptionsHeader {
  display: flex;
  justify-content: space-between;
}

#dishOptionsInfoIcon {
  cursor: pointer;
}

#dishOptionsInfoText {
  background-color: var(--white);
  border: 1px solid var(--action);
  border-radius: 5px;
  padding: 0.5em;
  text-align: center;
  width: 26%;
  position: absolute;
  right: 1.5em;
  top: 0.3em;
  font-family: var(--sansSerif);
  cursor: pointer;
}

.dishesThatInclude {
  display: flex;
  justify-content: space-between;
  margin: 0.3em 0 0 0;
  border-bottom: 1px solid var(--lightGray);
  font-size: 1.1em;
}

/* .dishesThatIncludeTitle > p {
  font-size: small;
  margin-top: -0.25em;
} */

.lockoutDishes {
  /* border: 1px solid red; */
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
}


/* Footer.css */

#footer {
	background-color: var(--background-color);
	border-top: 2px solid var(--action);
	font-family: var(--sansSerif);
	margin: 1em 0 0 0;
}

#footerLinks {
	display: flex;
	justify-content: space-evenly;
	padding: 1em 0 0 0;
}

#footerCopyright {
	display: flex;
	justify-content: space-around;
	font-size: .8em;
	margin: 1em 0 .5em 0;
}
h1 {
	/* color: green; */
}

.workingDishList {
	/* display: flex;
	flex-direction: column;
	width: 95%; */
	margin: 1em 0 0 2em;;

}

.workingDishList > h1 {
	margin: 0 0 .25em 0;
}

.dishListItem {
	margin-bottom: 0.25em;
}

.dishListLink {
	color: #209899;
	text-decoration: none;
	font-size: 1.2em;
}
/* NotFound.css */

#notFoundMsg {
  border: 1px solid var(--action);
  border-radius: 5px;
  width: 400px;
  height: 100px;
  margin: auto;
  padding: 2em;
  font-size: 1.5em;
  font-family: var(--sansSerif);
}
#aboutWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

 #aboutContent {
   width: 95%;
   max-width: 800px;
   font-family: var(--sansSerif);
  }

.aboutQuestion {
  border-left: 2px solid var(--action);
  margin: 0 0 0 1em;
}

.wondering {
  /* font-style: italic; */
  margin: 1em 0 0 .5em;
  font-size: 1.2em;
  /* font-family: var(--serif); */
  /* color: var(--action); */
  
}

.aboutQuestion > p {
  margin: .55em 0 0 .6em;
  /* color: red; */
  
}
/* Main.css */

:root {
  /* colors */
  --black: #272727;
  --teal: #209899;
  --mediumTeal: #79c1c1;
  --medLightTeal: #bce0e0;
  --lightTeal: #e8f4f4;
	--veryLightTeal: #F1F8F8;
  --white: #FAFAFA;
	--gray: #4b4b4c;
	--mediumGray: #c9c9c9;
	--lightGray: #DDDDDD;
	--coral: #fc9586;
	--lightCoral: #fedfda;

  /* fonts */
  --serif: adriane, serif;
  --sansSerif: 'Sarabun', sans-serif;



  /* purposes */
	--action: var(--teal);
	--excluded: var(--coral);
	--text: var(--black);
	--backgroundWhite: var(--white);

  /* visual distinctions*/
	--background-color: var(--veryLightTeal);
	--excluded-background-color: var(--lightCoral);

	/* media queries - NOT WORKING
	--mediaSmall: 768px;
	--mediaLarge: 769px;
	*/

}

html {
 	/* border-box box model allows us to add padding and border to our elements without increasing their size */
 	box-sizing: border-box;
	background-color: #FAFAFA;
	background-color: var(--backgroundWhite);
	color: #272727;
	color: var(--text);
	font-family: adriane, serif;
	font-family: var(--serif);
  /* scroll-behavior: smooth; */
	
}

.action {
	color: #209899;
	color: var(--action);
	cursor: pointer;
	text-decoration: none;
}

h1, h2, h3, h4 {
	font-family: 'Sarabun', sans-serif;
	font-family: var(--sansSerif);
}

/* google icon library */
.material-icons {
	color: #209899;
	color: var(--action);
	/* font-size: 2em; */
}

a {
	text-decoration: none;
}

/* .main-wrapper {
	max-width: 50px;
	margin: 0.5em;
	border: 1px solid green;
	font-family: 'Sarabun', sans-serif;
	
	font-weight: 400;
  font-style: normal;
} */

/* .main-wrapper > * {
	border: 1px solid red;
} */

/* Use flex box columns to keep footer at bottom of the page. */

#main-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: 1px solid red; */
	
}






	


body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4 {
  margin: 0;
}
