/* FilterStatusBox.css */

/* @media screen and (max-width: 600px) {
  .filterStatusBox {
    max-width: 100%;
  }
}
@media screen and (min-width: 601px) {
  .filterStatusBox {
    max-width: 500px;
  }
} */

.list {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25em;
}

.listItem {
  box-sizing: border-box;
  padding-left: 0.25em;
  margin: 0.5em 0.5em 0 0;
  display: flex;
  border-radius: 5px;
  align-items: center;
}

.includeItem {
  border: 1px solid var(--action);
  background-color: var(--background-color);
  display: flex;
}

.excludeItem {
  border: 1px solid var(--excluded);
  background-color: var(--excluded-background-color);
  display: flex;
}

.removeButton {
  cursor: pointer;
}

#ingFiltersClearAll {
  color: var(--action);
  cursor: pointer;
  border: 1.5px solid var(--action);
  padding: 0 5px 4px 5px;
  font-family: var(--sansSerif);
}