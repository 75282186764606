/* DishesIngSearchBar.css */

/* @media screen and (max-width: 600px) {
  .dishSearchBar {
    max-width: 100%;
  }
}
@media screen and (min-width: 601px) {
  .dishSearchBar {
    max-width: 500px;
  }
} */

#dishSearchBarInput {
  box-sizing: border-box;
  /* margin-left: 0.5em; */
  /* margin-right: 0.5em; */
  height: 35px;
  width: 100%;
  border: 1px solid var(--gray);
  font-size: 1.2em;
  padding-left: 6px;
  /* background-color: var(--white); */
}
