/* RecipeSources.css */

#recipeSourcesContainer {
  /* border: 1px solid pink; */
  margin-bottom: 0.5em;
}

.source {
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  /* border-bottom: 1px solid lightgray; */
  margin: 0.4em 0 0 1em;
  padding-right: .5em;
}

.source > input {
  width: 2em;
  height: 2em;
  margin-right: 0.5em;
  
}

.sourceLockedOut {
  color: gray;
}

.onlyButton {
  color: var(--action);
  margin: 0 .2em .2em .2em;
  /* font-size: .8em; */
  cursor: pointer;
  font-family: var(--sansSerif);
}
