/* ExistingSearchTerms.css */
#existingSearchTerms {
  margin: 0 0 0.5em 0;
  max-width: 600px;
  width: 95%;
}

#existingSearchTermsHeader {
  align-items: baseline;
  max-width: 800px;
}

#existingSearchTermsWrapper {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
}

.existingSearchTerm {
  border: 1px solid var(--action);
  box-sizing: border-box;
  padding-left: 0.5em;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  display: flex;
  border-radius: 5px;
  align-items: center;
}

#existingSearchTermClearAll {
  padding-right: 0.5em;
  color: var(--action);
  cursor: pointer;
  padding-left: 0.5em;
  border: 1.5px solid var(--action);
  font-family: var(--sansSerif);
  padding: 0 5px 5px 5px;
  font-size: 1.1em;
}
