/* Contact.css */

#contactWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contactContent {
  /* border: 1px solid var(--action); */
  border-radius: 5px;
  max-width: 600px;
  width: 95%;
  font-family: var(--sansSerif);
  font-size: 1.2em;
}

#contactHeader {
 margin: 0 0 1em 0;
}

#contactForm {
  /* margin: 10px; */
}

#contactEmailInput {
  width: 99%;
  margin-bottom: 1em;
  font-size: 1em;
}

#contactMessageTextarea {
  width: 98%;
  height: 300px;
  font-size: 1em;
  padding: 5px;
  font-family: var(--sansSerif);
}

#contactSubmit {
  color: var(--action);
  height: 40px;
  background-color: var(--background-color);
  font-size: 1em;
  border: 1px solid var(--black);
  border-radius: 5px;
}

#contactSubmit:active {
  color: var(--white);
  background-color: var(--action);
}

#submitConfirmSuccess {
  /* border: 1px solid lightgreen; */
  padding: 0.5em;
  margin-bottom: 10px;
}

#submitConfirmError {
  border: 1px solid lightcoral;
  padding: 0.5em;
  margin-bottom: 10px;
}