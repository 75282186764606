/* ComponentTabs.css */

.componentTabs {
  display: flex;
  margin-top: 1em;
}

.componentTab {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0.5em;
  margin-right: 0.5em;
  cursor: pointer;
}

.highlightedComponent {
  background-color: #81b8b8;
}