/* IngredientTree.css */

/* @media screen and (max-width: 600px) {
  .ingredientTree {
    max-width: 100%;
  }
}
@media screen and (min-width: 601px) {
  .ingredientTree {
    max-width: 500px;
  }
} */

#ingredientTree {
  position: relative;
  margin: .5em 0 0 0;
}

#ingInfoBox {
  background-color: var(--white);
  border: 1px solid var(--action);
  border-radius: 5px;
  padding: 0.5em;
  text-align: center;
  width: 100px;
  position: absolute;
  right: 5.5em;
  top: 0em;
  margin-top: 0.5em;
  font-family: var(--sansSerif);
  cursor: pointer;
}
