h1 {
	/* color: green; */
}

.workingDishList {
	/* display: flex;
	flex-direction: column;
	width: 95%; */
	margin: 1em 0 0 2em;;

}

.workingDishList > h1 {
	margin: 0 0 .25em 0;
}

.dishListItem {
	margin-bottom: 0.25em;
}

.dishListLink {
	color: #209899;
	text-decoration: none;
	font-size: 1.2em;
}