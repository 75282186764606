/* Home.js */
/* HomeSearchBar.js */
/* CommonlyPaired.js */
/* DishesThatInclude.js */

#homePage {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

#homeSplash {
  /* border: 1px solid red; */
  margin: 7em 0.5em 0.5em 0.5em;
  font-family: var(--sansSerif);
  max-width: 600px;
  width: 95%;
}

#warning {
  margin: 1em 0 0 0;
  color: var(--coral);
  display: flex;
  align-items: center;
}

#warning > span {
  color: var(--coral);
  margin: 0 0.25em 0 0;
}

/* ------------------------------------------- */
/* HomeSearchBar.js */

#homeSearchContainer {
  max-width: 600px;
  width: 95%;
}

/* #homeSearchBar {
  display: flex; 
  justify-content: space-around;
   max-width: 800px;
} */

#homeSearchBarInput {
  box-sizing: border-box;
  margin: 0 0 1em 0;
  height: 35px;
  width: 100%;
  border: 1px solid var(--gray);
  font-size: 1em;
  padding-left: 6px;
  /* background-color: var(--white); */
}

input:focus,
textarea:focus {
  outline: 0;
}

/* ---------------------------------------------- */

/* CommonlyPaired.js */

/* Gene's stuff 28 DEC 2021 */
#commonly {
  /* align-self: flex-start; */
  background-color: var(--background-color);
  padding: 0.5em 0 0.75em 0;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 600px;
}

#commonly > h3 {
  margin: 0 0 0 0.25em;
}

#optionsSlider {
  /* width: 100%;
  max-width: 600px; */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#optionsSlider > div {
  display: flex;
}

.commonlyIngredient {
  background-color: var(--backgroundWhite);
  color: var(--action);
  border: 1px solid var(--action);
  border-radius: 5px;
  /* padding: 0.2em 0.4em 0.2em 0.4em; */
  padding: 4px 6px 4px 6px;
  margin: 0.25em;
  cursor: pointer;
  /* font-weight: bold; */
}

.lockoutCP {
  /* border: 1px solid red; */
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
}

/* ------------------------------------------------- */

/* DishesThatInclude.js */

.dishOptions {
  max-width: 600px;
  width: 95%;
  margin: 0.5em 0 0 0;
  /* max-width: 800px; */
  position: relative;
}

#dishOptionsHeader {
  display: flex;
  justify-content: space-between;
}

#dishOptionsInfoIcon {
  cursor: pointer;
}

#dishOptionsInfoText {
  background-color: var(--white);
  border: 1px solid var(--action);
  border-radius: 5px;
  padding: 0.5em;
  text-align: center;
  width: 26%;
  position: absolute;
  right: 1.5em;
  top: 0.3em;
  font-family: var(--sansSerif);
  cursor: pointer;
}

.dishesThatInclude {
  display: flex;
  justify-content: space-between;
  margin: 0.3em 0 0 0;
  border-bottom: 1px solid var(--lightGray);
  font-size: 1.1em;
}

/* .dishesThatIncludeTitle > p {
  font-size: small;
  margin-top: -0.25em;
} */

.lockoutDishes {
  /* border: 1px solid red; */
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
}

