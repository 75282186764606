/* RecipeLinks.css */

#recipeLinks {
  margin: 1em 0 0 0;
  /* border: 1px solid red; */
  min-width: 330px;
  /* width: 100%; */
}

.recipeLink {
  border-left: 2px solid var(--mediumGray);
  padding-left: 5px;
  margin: 0 0 1em 0;
}

.sourceLink a {
  color: var(--action);
  cursor: pointer;
  text-decoration: none;
  font-size: 1.2em;
}

.sourceSite {
  display: flex;
  /* border: 1px solid red; */
  gap: 5px;
}

