/* FindARecipe.css */

#recipes {
  /* border: 2px solid purple; */
  /* width: 95%; */
  /* max-width: 600px; */
  width: 100%;
}


#recipesHeader {
  display: flex;
  flex-direction: column;
  font-family: var(--sansSerif);
  font-size: 1.25em;
  margin: 0.5em 0 0 0;
  /* padding: .25em; */
  border: 2px solid var(--action);
  border-radius: 5px;
}

/* @media (max-width: 768px) {
  #secondFilterStatusBox {
    
  }
} */

@media (min-width: 769px) {
   #secondFilterStatusBox {
    display: none;
  }
}

#filterBySourceHeader {
  display: flex;
  justify-content: space-between;
  
}

#filterBySourceButton {
  padding: 0.25em;
  color: var(--action);
  cursor: pointer;
  display: flex;
  flex-grow: 2;
  align-items: center;
  /* border: 1px solid green; */

}

#sourcesResetButton {
  /* margin: 0 0 0 1em; */
  color: var(--action);
  cursor: pointer;
  padding: 0.25em 0.5em 0 0;
}

