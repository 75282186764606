/*IngredientBranch.css*/



.ingredientLine {
  /* display: grid; */
  /* grid-template-columns: 20px auto; */
  display: flex;
  /* border: 1px solid red; */
}

.gen_0 {
  font-size: 1.2em;
}

.toggleButton {
  width: 24px;
  display: table;
  margin-left: -5px;
  cursor: pointer;
  /* border: 1px solid gray; */
}

.toggleButton > span {
  display: table-cell;
  vertical-align: middle;
}

.ingredientLineFlexWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  /* border: 1px solid orange; */
}

.ingredientLine > * {
  align-self: center;
}

.nameAndPercent {
  /* for the highlighting */
  margin-left: -4px;
  padding-left: 4px;
  margin-right: -3px;
  padding-right: 3px;
  border-radius: 5px;
  /* ------------------- */
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid yellow; */
}

.clickableName {
  cursor: pointer;
}

.highlight {
  background-color: rgba(235, 225, 52, 0.4);
}

.nameContainer {
  flex-grow: 2;
  min-height: 24px;
  position: relative;
  display: table;
  /* border: 1px solid greenyellow; */
  margin: 0 0.4em 0 0;
}

.name {
  display: table-cell;
  vertical-align: middle;
  line-height: 1.1em;
  padding: 4px 0 3px 0;
  /* display: table; */
  /* width: 100%; */
}

#ingredientInfoIcon {
  margin-right: 0.25em;
  vertical-align: middle;
  padding-top: 0.1em;
}

.percentage {
  font-size: 0.9em;
  margin: auto;
}


.filterButtons {
  display: grid;
  grid-template-columns: 25px 25px;
  color: var(--action);
  font-weight: bold;
  font-size: 1.5em;
  cursor: pointer;
  margin-left: 5px;
  margin-right: -4px;
  text-align: right;
}

.filterButton {
  display: table;
}

.filterButton > span {
  display: table-cell;
  vertical-align: middle;
}
