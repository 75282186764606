/* Header.css */


#header {
  padding: .75em .75em .5em .75em;
	margin: 0 0 1em 0;
  display: flex;
  justify-content: space-between;
	align-items: center;
	/* background: linear-gradient(to left, var(--teal), transparent); */
	background-color: var(--background-color);
	border-bottom: 2px solid var(--action);
	font-family: var(--sansSerif);
	font-size: 1.1em;
	color: var(--action);
}

#headerLogoSVG {
  width: 40px;
}

#menuLinks {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

#burgerWrapper {
  position: absolute;
  top: 16px;
  right: 16px;
  font-family: var(--sansSerif);
	font-size: 1.1em;
}

#burgerIcon {
  cursor: pointer;
}

#burgerIcon > span {
  font-size: 2em;
}

#navDropdown {
  width: 130px;
  position: absolute;
  top: 35px;
  background-color: var(--white);
  border: 2px solid var(--action);
  border-radius: 5px;
	padding: .5em;
  z-index: 1;
  right: 10px;
  display: flex;
  flex-direction: column;
  text-align: end;
}

.dropdownLink {
  font-size: 1.1em;
  margin-bottom: 0.5em;
  cursor: pointer;
}

@media (max-width: 1024px) {
  #menuLinks {
    display: none;
  }
}

/* @media (min-width: 769px) {
  #burgerWrapper {
    display: none;
  }
} */

@media (min-width: 1025px) {
  #burgerWrapper {
    display: none;
  }
}
