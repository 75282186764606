/* Main.css */

:root {
  /* colors */
  --black: #272727;
  --teal: #209899;
  --mediumTeal: #79c1c1;
  --medLightTeal: #bce0e0;
  --lightTeal: #e8f4f4;
	--veryLightTeal: #F1F8F8;
  --white: #FAFAFA;
	--gray: #4b4b4c;
	--mediumGray: #c9c9c9;
	--lightGray: #DDDDDD;
	--coral: #fc9586;
	--lightCoral: #fedfda;

  /* fonts */
  --serif: adriane, serif;
  --sansSerif: 'Sarabun', sans-serif;



  /* purposes */
	--action: var(--teal);
	--excluded: var(--coral);
	--text: var(--black);
	--backgroundWhite: var(--white);

  /* visual distinctions*/
	--background-color: var(--veryLightTeal);
	--excluded-background-color: var(--lightCoral);

	/* media queries - NOT WORKING
	--mediaSmall: 768px;
	--mediaLarge: 769px;
	*/

}

html {
 	/* border-box box model allows us to add padding and border to our elements without increasing their size */
 	box-sizing: border-box;
	background-color: var(--backgroundWhite);
	color: var(--text);
	font-family: var(--serif);
  /* scroll-behavior: smooth; */
	
}

.action {
	color: var(--action);
	cursor: pointer;
	text-decoration: none;
}

h1, h2, h3, h4 {
	font-family: var(--sansSerif);
}

/* google icon library */
.material-icons {
	color: var(--action);
	/* font-size: 2em; */
}

a {
	text-decoration: none;
}

/* .main-wrapper {
	max-width: 50px;
	margin: 0.5em;
	border: 1px solid green;
	font-family: 'Sarabun', sans-serif;
	
	font-weight: 400;
  font-style: normal;
} */

/* .main-wrapper > * {
	border: 1px solid red;
} */

/* Use flex box columns to keep footer at bottom of the page. */

#main-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: 1px solid red; */
	
}






	

