#aboutWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

 #aboutContent {
   width: 95%;
   max-width: 800px;
   font-family: var(--sansSerif);
  }

.aboutQuestion {
  border-left: 2px solid var(--action);
  margin: 0 0 0 1em;
}

.wondering {
  /* font-style: italic; */
  margin: 1em 0 0 .5em;
  font-size: 1.2em;
  /* font-family: var(--serif); */
  /* color: var(--action); */
  
}

.aboutQuestion > p {
  margin: .55em 0 0 .6em;
  /* color: red; */
  
}