/* HighlightsAndFiltersButtons.css */

#HaFbutton {
  border: 2px solid var(--action);
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
  color: var(--action);
  font-size: 1em;
  padding: 3px;
  cursor: pointer;
}
#HaFbutton > span {
  font-size: 1.25em;
  margin: 0.125em;
}

